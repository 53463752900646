import React, { useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import KLPleaseWait from "../../components/KLPleaseWait";
import { useStep } from "../../context/StepContext";
import useRedirectToken from "../../hooks/useRedirecToken";
import useVerifyToken from "../../hooks/useVerifyToken";

const AvailabilityScreen: React.FC = () => {
  const navigate = useNavigate();
  const { incrementStep, onRedirect } = useStep();
  const redirectToken = useRedirectToken();

  const {
    isAuthenticated,
    error,
    userData: tokenPayload,
  } = useVerifyToken(redirectToken);

  useEffect(() => {
    if (isAuthenticated && tokenPayload?.businessId) {
      const backendBaseUrl = process.env.REACT_APP_BACKEND_API;
      incrementStep();
      onRedirect(true);
      window.location.href = `${backendBaseUrl}/api/${tokenPayload.businessId}/slots/availability`;
    } else if (isAuthenticated && !tokenPayload?.businessId) {
      navigate("/not-authorized");
    }
  }, [isAuthenticated]);

  if (error) {
    return <Navigate to="/not-authorized" replace />;
  }
  return <KLPleaseWait text="עוד רגע זה קורה" />;
};

export default AvailabilityScreen;
