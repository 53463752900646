import React, { useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import KLButton from "../../components/KLButton";
import { cancelSlot } from "../../store/duckers/slots/thunk";
import { ButtonWrapper, Container, Title, InfoText, Subtitle } from "../styles";
import {
  ServiceDescription,
  ServicePriceDuration,
  SlotContentWrapper,
  SlotInfo,
  SlotItem,
  SlotWhen,
  CancellationExplanation,
} from "./styles";
import {
  checkCustomerSlots,
  fetchBusiness,
} from "../../store/duckers/customers/thunk";
import KLPleaseWait from "../../components/KLPleaseWait";
import {
  BusinessCategory,
  BusinessCategoryGender,
  getCategoryConfigTerms,
} from "../../constants/customers/categoryConfigMap";
import { durationOptions } from "../../utils";
import useVerifyToken from "../../hooks/useVerifyToken";
import useRedirectToken from "../../hooks/useRedirecToken";

const CustomerExistingSlotsScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { businessId } = useParams<{ businessId: string }>();
  const redirectToken = useRedirectToken();
  const {
    isLoading: isVerifying,
    isAuthenticated,
    error: verificationError,
    userData,
  } = useVerifyToken(redirectToken);
  const {
    fetchingBusiness,
    fetchingCustomerSlots,
    customerPhone,
    customerSlots,
    business,
  } = useSelector((state: RootState) => state.customers);
  const { cancellingSlot, cancelSlotSucceeded } = useSelector(
    (state: RootState) => state.slots,
  );
  const [cancellingSlotId, setCancellingSlotId] = useState<string | null>(null);

  const phone = useMemo(
    (): string => userData?.customerPhone || customerPhone,
    [userData, customerPhone],
  );

  useEffect(() => {
    if (!businessId) {
      navigate("/not-authorized");
      return;
    }

    if (isAuthenticated && phone) {
      dispatch(fetchBusiness(businessId));
      dispatch(checkCustomerSlots({ businessId, phone }));
    } else if (isAuthenticated && !phone) {
      navigate("/not-authorized");
    }
  }, [isAuthenticated, userData, businessId, dispatch, navigate]);

  useEffect(() => {
    if (cancelSlotSucceeded) {
      setCancellingSlotId(null);
    }
  }, [cancelSlotSucceeded]);

  const handleCancelRequest = (slotId: string) => {
    if (!businessId) {
      return;
    }
    setCancellingSlotId(slotId);
    dispatch(cancelSlot({ businessId, slotId }));
  };

  const handleNewAppointment = () => {
    navigate(`/${businessId}/services`);
  };

  const calculateDuration = (start: string, end: string) => {
    const startTime = DateTime.fromISO(start);
    const endTime = DateTime.fromISO(end);
    const durationInMinutes = endTime.diff(startTime, "minutes").minutes;
    return (
      durationOptions.find((option) => option.value === durationInMinutes)
        ?.label || `${durationInMinutes} דקות`
    );
  };

  const formatWhen = (start: string, end: string) => {
    const startTime = DateTime.fromISO(start).setZone("Asia/Jerusalem");
    const endTime = DateTime.fromISO(end).setZone("Asia/Jerusalem");

    const dayOfWeekHebrew = startTime.setLocale("he").toFormat("cccc");
    const formattedDate = startTime.toFormat("dd/MM");
    const startEndTime = `${startTime.toFormat("HH:mm")} - ${endTime.toFormat("HH:mm")}`;

    return {
      date: `${dayOfWeekHebrew}, ${formattedDate}`,
      time: startEndTime,
    };
  };

  const { term, pluralTerm, gender } = getCategoryConfigTerms(
    business?.category as BusinessCategory,
  );

  const generateCancellationExplanation = (cancellationPolicy: number) => {
    let explanation = "";
    const starts = gender === BusinessCategoryGender.Male ? "מתחיל" : "מתחילה";
    if (cancellationPolicy === 1) {
      explanation = `לא ניתן לבטל, ${term} ${starts} עוד פחות משעה`;
    } else if (cancellationPolicy === 2) {
      explanation = `לא ניתן לבטל, ${term} ${starts} עוד פחות משעתיים`;
    } else if (cancellationPolicy > 2) {
      explanation = `לא ניתן לבטל, ${term} ${starts} עוד פחות מ-${cancellationPolicy} שעות`;
    }

    return explanation && business ? (
      <>
        {explanation}
        <br />
        לבירורים אפשר ליצור קשר עם {business.businessName}
        <br />
        בטלפון {business.phone}
      </>
    ) : (
      ""
    );
  };

  const canCancel = (start: string, cancellationPolicy: number) => {
    const startTime = DateTime.fromISO(start).setZone("Asia/Jerusalem");
    const now = DateTime.now().setZone("Asia/Jerusalem");

    const hoursUntilStart = startTime.diff(now, "hours").hours;
    return hoursUntilStart > cancellationPolicy;
  };

  if (isVerifying || fetchingCustomerSlots || fetchingBusiness) {
    return <KLPleaseWait text="עוד רגע זה קורה" />;
  }

  if (verificationError) {
    return <Navigate to="/not-authorized" replace />;
  }

  return (
    <Container>
      <Title>
        ה{pluralTerm} ה
        {gender === BusinessCategoryGender.Female ? "קיימות" : "קיימים"} שלך
      </Title>
      {customerSlots.length === 0 ? (
        <>
          <Subtitle>
            נראה שכרגע אין לך {pluralTerm}{" "}
            {gender === BusinessCategoryGender.Female ? "עתידיות" : "עתידיים"}{" "}
            פה
          </Subtitle>
          <InfoText>
            אבל אפשר לקבוע{" "}
            {gender === BusinessCategoryGender.Female ? "חדשות" : "חדשים"}!
          </InfoText>
        </>
      ) : (
        customerSlots.map((customerSlot, index) => {
          const when = formatWhen(customerSlot.start, customerSlot.end);
          const cancellationDisabled = !canCancel(
            customerSlot.start,
            customerSlot.cancellationPolicy,
          );
          const explanation = cancellationDisabled
            ? generateCancellationExplanation(customerSlot.cancellationPolicy)
            : "";

          return (
            <SlotItem key={`${customerSlot.id}-${index}`}>
              <SlotContentWrapper>
                <SlotInfo>
                  <ServiceDescription>
                    {customerSlot.service}
                  </ServiceDescription>
                  <ServicePriceDuration>
                    {customerSlot.price && `${customerSlot.price} ש״ח , `}
                    {calculateDuration(customerSlot.start, customerSlot.end)}
                  </ServicePriceDuration>
                  <SlotWhen>
                    <span>{when.date}</span>, <span>{when.time}</span>
                  </SlotWhen>
                </SlotInfo>
                <KLButton
                  title="ביטול"
                  onClick={() => handleCancelRequest(customerSlot.id)}
                  warning
                  noWidth
                  noMarginTop
                  disabled={cancellationDisabled || cancellingSlot}
                  loading={
                    cancellingSlot && cancellingSlotId === customerSlot.id
                  }
                />
              </SlotContentWrapper>
              {cancellationDisabled && (
                <CancellationExplanation>{explanation}</CancellationExplanation>
              )}
            </SlotItem>
          );
        })
      )}
      <ButtonWrapper>
        <KLButton
          title={`קביעת ${term} ${gender === BusinessCategoryGender.Male ? "חדש" : "חדשה"}`}
          onClick={handleNewAppointment}
        />
      </ButtonWrapper>
    </Container>
  );
};

export default CustomerExistingSlotsScreen;
