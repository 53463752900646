import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import KLButton from "../../components/KLButton";
import KLTextInput from "../../components/KLTextInput";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  setCustomerName,
  setCustomerPhone,
} from "../../store/duckers/customers/slice";
import {
  Container,
  FieldWrapper,
  FormWrapper,
  InfoText,
  TextIconContainer,
  Title,
} from "../styles";
import KLCustomerBookingProgress from "../../components/KLCustomerBookingProgress";
import { useStep } from "../../context/StepContext";
import { checkCustomerSlots } from "../../store/duckers/customers/thunk";
import KLPleaseWait from "../../components/KLPleaseWait";
import useLocalStorage from "../../hooks/useLocalStorage";
import { STORAGE_KEYS } from "../../constants/localStorage";
import {
  BusinessCategory,
  BusinessCategoryGender,
  getCategoryConfigTerms,
} from "../../constants/customers/categoryConfigMap";
import { EmojiPeople } from "@mui/icons-material";
import { validatePhone } from "../../utils";

const CustomerInfoScreen: React.FC = () => {
  const navigate = useNavigate();
  const { incrementStep } = useStep();
  const dispatch = useDispatch<AppDispatch>();
  const { businessId } = useParams<{ businessId: string }>();

  const { storedValue: storedName, setValue: setStoredName } =
    useLocalStorage<string>(STORAGE_KEYS.CustomerName, "");

  const { storedValue: storedPhone, setValue: setStoredPhone } =
    useLocalStorage<string>(STORAGE_KEYS.CustomerPhone, "");

  const [name, setName] = useState<string>(storedName);
  const [phone, setPhone] = useState<string>(storedPhone);
  const [confirmDetailsClicked, setConfirmDetailsClicked] =
    useState<boolean>(false);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);

  const { fetchingCustomerSlots, customerSlots, business } = useSelector(
    (state: RootState) => state.customers,
  );

  const { pluralTerm, gender } = getCategoryConfigTerms(
    business?.category as BusinessCategory,
  );

  const isFormValid = useMemo(() => {
    return name.trim() !== "" && isPhoneValid;
  }, [name, isPhoneValid]);

  const handleConfirm = () => {
    if (isFormValid && businessId) {
      dispatch(setCustomerName(name));
      dispatch(setCustomerPhone(phone));
      dispatch(checkCustomerSlots({ businessId, phone }));
      setStoredName(name);
      setStoredPhone(phone);
      setConfirmDetailsClicked(true);
    }
  };

  useEffect(() => {
    if (phone) {
      setIsPhoneValid(validatePhone(phone));
    }
  }, [phone]);

  useEffect(() => {
    if (!confirmDetailsClicked || fetchingCustomerSlots) {
      return;
    } else if (customerSlots.length > 0) {
      incrementStep();
      navigate(`/${businessId}/existingSlots`);
    } else {
      incrementStep();
      navigate(`/${businessId}/services`);
    }
  }, [
    confirmDetailsClicked,
    fetchingCustomerSlots,
    customerSlots,
    businessId,
    incrementStep,
    navigate,
  ]);

  return (
    <Container>
      <KLCustomerBookingProgress currentStep={1} />
      {fetchingCustomerSlots ? (
        <KLPleaseWait
          text={`בודקים ${pluralTerm} ${gender === BusinessCategoryGender.Male ? "קיימים" : "קיימות"}`}
        />
      ) : (
        <>
          <TextIconContainer>
            <EmojiPeople />
            <Title>עם מי יש לנו הכבוד?</Title>
          </TextIconContainer>
          <InfoText>
            נשמח לקבל מכם שם וטלפון כדי לקבוע לכם {pluralTerm}{" "}
            {gender === BusinessCategoryGender.Male ? "חדשים" : "חדשות"} ולבדוק
            אם יש כבר{" "}
            {gender === BusinessCategoryGender.Male ? "קיימים" : "קיימות"}.
          </InfoText>

          <FormWrapper
            onSubmit={(e) => {
              e.preventDefault();
              handleConfirm();
            }}
          >
            <FieldWrapper>
              <KLTextInput
                type="text"
                placeholder="שם ומשפחה"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FieldWrapper>
            <FieldWrapper>
              <KLTextInput
                type="tel"
                placeholder="טלפון"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                onValidationChange={setIsPhoneValid}
              />
            </FieldWrapper>
            <FieldWrapper>
              <KLButton title={"ממשיכים"} disabled={!isFormValid} />
            </FieldWrapper>
          </FormWrapper>
        </>
      )}
    </Container>
  );
};

export default CustomerInfoScreen;
