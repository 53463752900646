import React from "react";
import KLPleaseWait from "../../components/KLPleaseWait";
import { useStep } from "../../context/StepContext";

interface SequentialRouteProps {
  requiredStep: number;
  element: React.ReactElement;
}

const SequentialRoute: React.FC<SequentialRouteProps> = ({
  requiredStep,
  element,
}) => {
  const { currentStep, setStep } = useStep();

  React.useEffect(() => {
    setStep(requiredStep);
  }, [requiredStep, setStep]);

  if (currentStep < requiredStep) {
    return <KLPleaseWait text="Processing steps..." />;
  }

  return element;
};

export default SequentialRoute;
