import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyTokenThunk } from "../store/duckers/tokens/thunk";
import { RootState, AppDispatch } from "../store";

interface UseVerifyTokenReturn {
  isLoading: boolean;
  isAuthenticated: boolean;
  error: string | null;
  userData: any;
}

const useVerifyToken = (redirectToken: string): UseVerifyTokenReturn => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    isTokenVerified,
    verifyingToken,
    userData,
    error: tokenError,
  } = useSelector((state: RootState) => state.tokens);

  const { customerPhone: customerPhoneFromCustomers } = useSelector(
    (state: RootState) => state.customers,
  );

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const verify = async () => {
      if (redirectToken) {
        try {
          await dispatch(verifyTokenThunk(redirectToken)).unwrap();
        } catch (err: any) {
          setError(err.message || "Token verification failed");
        }
      } else if (customerPhoneFromCustomers) {
      } else {
        setError("No token provided and user is not authenticated");
      }
    };

    verify();
  }, [redirectToken, customerPhoneFromCustomers, dispatch]);

  useEffect(() => {
    if (tokenError) {
      setError(tokenError);
    }
  }, [tokenError]);

  return {
    isLoading: verifyingToken,
    isAuthenticated: redirectToken
      ? isTokenVerified
      : !!customerPhoneFromCustomers,
    error,
    userData,
  };
};

export default useVerifyToken;
